import moment from "moment";
import { mapState } from "vuex";

export const helper = {
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
    	/**
    	 * 根据broker id获取broker名字
    	 * @param  {[type]} brokerId [description]
    	 * @return {[type]}          [description]
    	 */
        getBrokerNameById (brokerId) {
            const result = this.brokerMap.filter(item => item.broker_id == brokerId);
            if (result.length != 0) return result[0].broker;
            return "";
        },
        /**
		 * 根据broker name获取broker id
		 * @param  {[type]} brokerName [description]
		 * @return {[type]}            [description]
		 */
        getBrokerIdByName (brokerName) {
            const result = this.brokerMap.filter(item => item.broker == brokerName);
            if (result.length != 0) return result[0].broker_id;
            return -1;
        },
        /**
		 * Fetch broker's book list
		 * @param  {[type]} brokerId [description]
		 * @return {[type]}          [description]
		 */
        getBookListByBrokerId (brokerId) {
            const bookMap = JSON.parse(localStorage.getItem("bm"));
            const result = bookMap.filter(item => item.broker_id == brokerId);
            return result;
        },
        /**
		 * 检查是否为空
		 * @param  {[type]} content [description]
		 * @return {[type]}         [description]
		 */
        checkEmptyField (content) {
            if (content === undefined || content === null || content === "") return true;
            return false;
        },
        /**
         * Trim Symbol
         * @param {*} room
         */
        trimSymbol (symbol, rules) {
            for (let i = 0; i < rules.length; i++) {
                if (symbol.indexOf(rules[i]) != -1) {
                    return symbol.split(rules[i])[0];
                }
            }
            return symbol;
        },
        getYesterday () {
	        let currentDate = moment();

	        if (currentDate.days() == 0) {
	            currentDate = currentDate.subtract(2, "days");
	        } else if (currentDate.days() == 6) {
	            currentDate = currentDate.subtract(1, "days");
	        } else if (currentDate.days() == 1) {
	            currentDate = currentDate.subtract(3, "days");
	        } else {
	            currentDate = currentDate.subtract(1, "days");
	        }

	        if (currentDate.format("MM-DD") == "01-01" || currentDate.format("YYYY-MM-DD") == "12-25") {
	            currentDate = currentDate.subtract(1, "days");
	        }

	        return currentDate;
        },
        getTenDaysData () {
            let currentDate = moment();
            currentDate = currentDate.subtract(14, "days");
            return currentDate;
        },
        /**
		 * check form's field is valid or not
		 * Eg: empty or null is invalid
		 * @param {*} ts
		 */
        formatDate (ts) {
            const current = new Date(ts);

            const result = "";
            let month = "" + (current.getMonth() + 1);
            let day = "" + current.getDate();
            const year = current.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        /**
		 * check form's field is valid or not
		 * Eg: empty or null is invalid
		 * @param {*} ts
		 */
        formatTimestamp(ts) {
            const current = new Date(ts);

            let result = "";
            let month = "" + (current.getMonth() + 1);
            let day = "" + current.getDate();
            const year = current.getFullYear();

            const hour = current.getHours();
            const minutes = current.getMinutes();
            const sec = current.getSeconds();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            result = [year, month, day].join("-");
            result += " ";
            result += hour + ":" + minutes + ":" + sec;
            
            return result;
        },
        /**
         * Check if user visit this site via mobile agent or not
         * @return  {[type]}  [return description]
         */
        isMobile() {
            if (navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/iPad/i) ||
				navigator.userAgent.match(/iPod/i) ||
				navigator.userAgent.match(/BlackBerry/i) ||
				navigator.userAgent.match(/Windows Phone/i)
            ) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check if data is valid json or not
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        isValidJson(data) {
            try {
                if (typeof data === "object" && data !== null) {
                    return true;
                } else {
                    JSON.parse(data);
                }
            } catch (e) {
                return false;
            }

            return true;
        },
        /**
		 * Check if object key exists and not null
		 * @return  {[type]}  [return description]
		 */
        checkValidObjectKey(obj, key) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] == undefined || obj[key] == null) return false;
            } else {
                return false;
            }

            return true;
        },
        /**
		 * Round particular number with decimal place
		 * @param   {[type]}  data   [data description]
		 * @param   {[type]}  place  [place description]
		 * @return  {[type]}         [return description]
		 */
        roundDecimal(data, place) {
            if (data == null || data == undefined) return data;
            if (isNaN(data)) return data;
            return data.toFixed(place);
        },
        /**
		 * Compare two value
		 *
		 * @param oldvalue type string/int
		 * @param	newvalue type string/int
		 *
		 * @retrun number -1/0/1 represents less/same/bigger
		 */
		 compareUpdatedValue (ov, nv) {
            if (typeof ov !== typeof nv) {
                console.log("comparing values are different types");
                return undefined;
            } else {
                if (typeof nv === "string") {
                    return nv.localeCompare(ov);
                } else {
                    if (nv > ov) {
                        return 1;
                    } else if (nv < ov) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }
        },
        /**
		 * get the last sunday date of a given month in a given year
		 *
		 * @param year type string/int
		 * @param	month type string/int
		 *
		 * @retrun sunday date type Date
		 */
		 currentYaerlastSunday (year, month) {
            const d = new Date(year, month, 0);
            d.setDate(d.getDate() - d.getDay());
            const date = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 1));
            return date;
        },

        /**
		 * source https://en.m.wikipedia.org/wiki/Summer_time_in_Europe
		 * European Summer Time begins (clocks go forward) at 01:00 UTC on the last Sunday in March,
		 * and ends (clocks go back) at 01:00 UTC on the last Sunday in October
		 *
		 * @param localdate type Date
		 *
		 * @return offset type number
		 */
        getMT4OfferSet (localdate) {
            const MARCH = 3;
            const OCTOBER = 10;
            const OneHourToMins = 60;
            const SUMMEROFFSET = -3;
            const WINTEROFFSET = -3;

            const summerstart = this.currentYaerlastSunday(localdate.getUTCFullYear(), MARCH);
            const summerend = this.currentYaerlastSunday(localdate.getUTCFullYear(), OCTOBER);

            const offset = localdate.getTimezoneOffset() / OneHourToMins;

            localdate.setHours(localdate.getHours() + offset);

            if (localdate >= summerstart && localdate <= summerend) {
                return offset - SUMMEROFFSET;
            } else {
                return offset - WINTEROFFSET;
            }
        }, 

        /**
         * compare two array
         * 
        */
        compareArray(a, b) {
            a = Array.isArray(a) ? a : [];
            b = Array.isArray(b) ? b : [];
            return a.length === b.length && a.every(el => b.includes(el));
        },
        getwidthofstr(key, value) {

			return 140;
            // const res = key.length < value.length ? value.length * 10: key.length * 11 + 5
            // return res < 100 ? 100 : res > 300 ? 265 : res
        },

        /**
         * create v-data-table header
         * 
         * @param dataObj objective of data in the table
         * 
         * @return objective of header
         */
        createHeader (dataObj) {
            let header = []
            for (let key in dataObj) {

                const upperLetterArr = key.split('_').map(ele => {
                    return ele[0].toUpperCase() + ele.slice(1);
                })
                
                const text = upperLetterArr.join('')
                const headerEle = {'text':text, 'value': key}

                // can add more feature
                    // e.g., sortable, width
                
                header.push(headerEle)
            }
            return header
        }
    }
};
