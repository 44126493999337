import * as apiUserStatus from "@components/api/pk/user-status";
import * as apiUserPermission from "@components/api/pk/user-permission";
import * as apiSettingsSamplePermission from "@components/api/pk/settings-sample-permission";
import * as apiUser from "@components/api/pk/user";

const state = {
    listUser: [],
    loading: false,
    headers: [
        { text: "Email", value: "email", fixed: true },
        { text: "ID", value: "id" },
        { text: "Username", value: "username" },
        { text: "First", value: "first_name" },
        { text: "Last", value: "last_name" },
        { text: "Last Login", value: "last_login" },
        { text: "Role", value: "role" },
        { text: "JTI", value: "jti" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Active", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false, fixed: true }
    ],
    permissionData: [],
    permissionList: []
};

const actions = {
    /**
     * User api to load user
     * @return  {[type]}  [return description]
     */
    userLoad({ commit }) {
        commit("UPDATE_LOADING", true);
        apiUser.fetch().then((res) => {
            let snackbar = {
                message: "Load user successfully",
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LIST_USER", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * User create via API
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    userCreate({ commit, dispatch, rootState }, dialog) {

        // check if permission is a valid json or not
        commit("UPDATE_IS_VALID_JSON", false, { root: true });
        commit("IS_VALID_JSON", dialog.permission, { root: true });

        // if not valid json, popup error message
        if (!rootState.isValidJson) {
            let snackbar = {
                message: "Invalid Json",
                color: "red",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            return;
        }

        let params = {
            email: dialog.email,
            password: dialog.password,
            username: dialog.username,
            firstname: dialog.firstname,
            lastname: dialog.lastname,
            permission: dialog.permission,
            role: dialog.role,
            id_broker: dialog.brokerId
        };
        commit("UPDATE_LOADING", true);

        apiUser.create(params).then(() => {
            let snackbar = {
                message: "Create User Successfully",
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            dispatch("userLoad");
        }).catch(() => {
            commit("UPDATE_LOADING", false);
        })
    },
    /**
     * Update existing user
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  dialog    [dialog description]
     * @return  {[type]}          [return description]
     */
    userUpdate({ commit, dispatch, rootState }, dialog) {

        // check if permission is a valid json or not
        commit("UPDATE_IS_VALID_JSON", false, { root: true });
        commit("IS_VALID_JSON", dialog.permission, { root: true });

        // if not valid json, popup error message
        if (!rootState.isValidJson) {
            let snackbar = {
                message: "Invalid Json", color: "red", btnName: "Close",
                status: true, timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            return;
        }

        let params = {
            id: dialog.id,
            email: dialog.email,
            password: dialog.password,
            username: dialog.username,
            firstname: dialog.firstname,
            lastname: dialog.lastname,
            permission: dialog.permission,
            role: dialog.role,
            id_broker: dialog.brokerId
        };
        
        commit("UPDATE_LOADING", true);

        apiUser.update(params).then(() => {
            let snackbar = {
                message: "Update User Successfully", color: "green", btnName: "Close",
                status: true, timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            dispatch("userLoad");
        }).catch(() => {
            commit("UPDATE_LOADING", false);
        })
    },
    /**
     * Update account status
     * @param   {[type]}  status  [status description]
     * @return  {[type]}          [return description]
     */
    userStatus({ state, commit }, item) {
        
        let params = { jti: item.jti, email: item.email };

        if (item.is_active) params["status"] = "enable";
        if (!item.is_active) params["status"] = "disable";

        commit("UPDATE_LOADING", true);
        apiUserStatus.update(params).then(() => {
            let snackbar = {
                message: "Update Status Successfully", color: "green", btnName: "Close",
                status: true, timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            let index = state.listUser.findIndex(data => data.email === item.email);
            state.listUser[index].is_active = item.is_active;
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Load permission
     * @return  {[type]}  [return description]
     */
    permissionLoad({ commit }) {
        apiSettingsSamplePermission.fetch().then(res => {
            commit("UPDATE_PERMISSION_DATA", res.data);
            localStorage.setItem("permissionData", JSON.stringify(res.data));
            let permissionList = res.data.map(i => i.role);
            commit("UPDATE_PERMISSION_LIST", permissionList);
        });
    },
    /**
     * Update permission for selected users
     * @param   {[type]}  commit     [commit description]
     * @param   {[type]}  rootState  [rootState description]
     * @param   {[type]}  data       [data description]
     * @return  {[type]}             [return description]
     */
    permissionUpdate({ commit, rootState }, data) {
        commit("UPDATE_LOADING", true);

        let selectedUsers = data.selectedUsers;
        let batch = data.batch;

        let configParam = {};

        // check if permission is a valid json or not
        commit("UPDATE_IS_VALID_JSON", false, { root: true });
        commit("IS_VALID_JSON", batch.permission, { root: true });

        // if not valid json, popup error message
        if (!rootState.isValidJson) {
            let snackbar = {
                message: "Invalid Json", color: "red", btnName: "Close",
                status: true, timeout: 2000,
            };

            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            return;
        }
        
        configParam = batch.permission;

        const pkid = [];
        for (let i = 0; i < selectedUsers.length; i++) {
            pkid.push(selectedUsers[i].id);
        }

        const params = { ids: pkid, permission: configParam };
        console.log("Parmas: ", params);
        commit("UPDATE_LOADING", true);

        apiUserPermission.update(params).then(() => {
            let snackbar = {
                message: "Update Permission Successfully", color: "green", btnName: "Close",
                status: true, timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
        });
    }
};

const mutations = {
    /**
     * Update permission data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_PERMISSION_DATA(state, data) {
        state.permissionData = data;
    },
    /**
     * Update permission list
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_PERMISSION_LIST(state, data) {
        state.permissionList = data;
    },
    /**
     * Update List User
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LIST_USER(state, data) {
        state.listUser = data;
    },
    UPDATE_DIALOG(state, data) {

    },
    /**
     * Update loading status
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}