export default {
    /**
     * Filter alert by broker's book
     * @param   {[type]}  data      [data description]
     * @param   {[type]}  brokerId  [brokerId description]
     * @return  {[type]}            [return description]
     */
    globalAlertFilter(data, brokerId) {
        let filterBook = localStorage.getItem("alertHideBroker");
        if (filterBook !== undefined && filterBook !== null && data !== undefined) {
            filterBook = JSON.parse(filterBook);

            if (filterBook.hasOwnProperty(brokerId)) {
                let filterBrokerBook = filterBook[brokerId];
                let result = data.filter((item) => !filterBrokerBook.includes(item.book));
                return result;
            }
            return data;
        } 
    }
}