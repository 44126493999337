import * as apiBookGroupRule from "@components/api/pk/book-group-rule";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";

const state = {
    initlization: true,
    bookGroupRules: [],
    bookGroupRulesHeader: [],
    keyForUserInput: [
        "rules",
        "priority",
        "name",
        "oz_book",
        "book_to_oz",
        "book_to_pk",
        "server"
    ],
    keyReqNumber: ["proprity"],
    serverSelection: ["Other", "MT4", "MT4-2", "MT4-3", "MT4-4"],
    uniqueInputTypeforKeys: {
        server: "SelectionandTextField"
    }
};

const actions = {
    loadBookGroupRules ({ commit, state }, param) {
        console.log(param);
        apiBookGroupRule.fetch(param).then((res) => {
            console.log(res.data);
            console.log("UPDATE_LOAD_DATA", res.data);
            commit("UPDATE_LOAD_DATA", res.data);
            console.log(state.initlization);
            console.log(res.data[0]);
            if (state.initlization) {
                const header = helper.methods.createHeader(res.data[0]);
                // header.push({
                //     text: 'Actions',
                //     value: 'actions',
                //     sortable: false ,
                // })
                commit("CREATE_HEADER", header);
                commit("SET_INITAL", false);
            }
            snackbar.methods.snackBarSuccess("Load book group rules successfully", undefined, undefined, this.state);
        });
    },
    deleteBookGroupRules ({ commit, dispatch }, { paramDelete, paramLoad }) {
        console.log(paramDelete, paramLoad);
        apiBookGroupRule.deleteData({ ...paramDelete, ...paramLoad }).then((res) => {
            console.log(res.data);
            dispatch("loadBookGroupRules", paramLoad);
        });
    },
    editBookGropuRules ({ commit, dispatch }, { paramEdit, paramLoad }) {
        console.log(paramEdit, paramLoad);
        console.log({ ...paramEdit, ...paramLoad });
        const param = { ...paramEdit, ...paramLoad };
        delete param.created_at;
        delete param.updated_at;
        console.log(param);
        apiBookGroupRule.edit(param).then((res) => {
            console.log(res.data);
            dispatch("loadBookGroupRules", paramLoad);
        }).then(() => {
            snackbar.methods.snackBarSuccess("Update book group rules successfully", undefined, undefined, this.state);
        });
    },

    createBookGroupRules ({ commit, dispatch }, { paramCreate, paramLoad }) {
        console.log("paramCreate", paramCreate, "paramLoad", paramLoad);
        const paramCreatewithBroker = [];
        for (const serverName of paramCreate.server) {
            const cp = Object.assign({}, paramCreate);
            cp.server = serverName;
            paramCreatewithBroker.push(cp);
        }
        console.log(paramCreatewithBroker);
        apiBookGroupRule.create(paramCreatewithBroker).then((res) => {
            console.log(res.data);
            dispatch("loadBookGroupRules", paramLoad);
        });
    },
    clearBookGroupRules ({ commit }) {
        commit("UPDATE_LOAD_DATA", []);
    }
};

const mutations = {
    UPDATE_LOAD_DATA (state, bookGroupRules) {
        state.bookGroupRules = bookGroupRules;
    },
    CREATE_HEADER (state, bookGroupRulesHeader) {
        state.bookGroupRulesHeader = bookGroupRulesHeader;
    },
    SET_INITAL (state, intial) {
        state.initlization = intial;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
