import request from "@utils/pk/request";

export function fetch (query) {
    return request({
        url: "trades",
        method: "get",
        params: query
    });
}

export function create (query) {
    return request({
        url: "trades",
        method: "post",
        data: query
    });
}

export function update (query) {
    return request({
        url: "trades",
        method: "put",
        data: query
    });
}

export function remove (query) {
    return request({
        url: "trades",
        method: "delete",
        data: query
    });
}
