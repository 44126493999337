import axios from "axios";
import store from "@store/store";
import router from "../../router/index.js";

const loadCounter = (function () {
    let count = 0;
    let loadingInstance;
    const show = () => {
        if (count > 0) {
            // show loading
            // store.state.snackbar = true;
            // store.state.snackmsg = "Data Fetching...";
        } else {
            if (store.state.snackbar) {
                // store.state.snackbar = false;
            }
        }
    };
    return {
        increment () {
            count++;
            show();
        },
        decrement () {
            count--;
            show();
        }
    };
})();

const APIURL = "https://dev-api-pk.sigmarisk.xyz/api/v1/";
// const APIURL = "http://127.0.0.1:4444/api/v1/";
// const APIURL = "https://api-pk.sigmarisk.xyz/api/v1/";


// create an axios instance
const service = axios.create({
    baseURL: APIURL,
    timeout: 30000,
    crossDomain: true
});

const source = axios.CancelToken.source();

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// request interceptor
service.interceptors.request.use(config => {
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded;charset=UTF-8";

    const token = "Bearer " + localStorage.getItem("bst");
    config.headers.Authorization = token;

    loadCounter.increment();

    if (config.method.toLocaleLowerCase() == "get") {
        // if get method add key to url
    }

    // if (config.method == 'get') {
    // config.data = qs.stringify(config.data)
    // }

    return config;
}, error => {
    // do sth with request error
    // console.log(error)
    loadCounter.decrement();
    Promise.reject(error);
});

service.interceptors.response.use(
    response => {

        // loadCounter.decrement();

        return response;
    },
    error => {
        if (error.response) {
            
            
            console.log("Err: ", error.response);
            switch (error.response.status) {
            // case 200:
            //     store.state.snackcolor = "green";
            //     store.state.snackmsg = "Successful";
            //     store.state.snachbar = true;
            //     break
            case 400:
                let params = {
                    color: "red",
                    message: error.response.data.message,
                    status: true,
                    btnName: "Close",
                    timeout: 1000
                }

                store.commit("UPDATE_SNACKBAR", params);

                // store.state.snackcolor = "red";
                // store.state.snackmsg = error.response.data.message;
                // store.state.snachbar = true;
                // console.log("mother fucker");
                break;
            case 404:
                store.state.snackcolor = "red";
                store.state.snackmsg = "Error 404, Page Not Found";
                store.state.snachbar = true;
                break;
            case 401:
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = "Error 401, Auth Expired or Incorrect";

                localStorage.clear();
                // store.state.loginError = true;
                source.cancel("");
                if (window.location.href.indexOf("signin") == -1) {
                    // self.$router.push({name: 'SigninPage'});
                    window.location.href = "/#/signin";
                }
                break;
            case 500:
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                // console.log(response)
                // if (response.message != null) {
                //     store.state.snackmsg = response.message;
                // } else {
                store.state.snackmsg = "Error 500, Internal Server Error";
                // }
                break;
            case 429:

                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = "Too many request";
                break;
            case 403:
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = "Permission Denied";
                console.log("Permission Denied!");
                router.go(-1);
                break;
            case 600:
                console.log("Error 600");
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = error.response.data.message;
                break;
            }
            // 在响应错误的时候的逻辑处理
            return Promise.reject(error);
        }
    }
);

export default service;
