import request from "@utils/pk/request";

export function fetch (query) {
    return request({
        url: "group",
        method: "get",
        params: query
    });
}

export function create (query) {
    return request({
        url: "group",
        method: "post",
        data: query
    });
}
