import request from "@utils/pk/request";

export function fetch (query) {
    return request({
        url: "book-group-rules",
        method: "get",
        params: query
    });
}

export function create (query) {
    return request({
        url: "book-group-rules",
        method: "post",
        data: query
    });
}

export function deleteData (query) {
    return request({
        url: "book-group-rules",
        method: "delete",
        data: query
    });
}

export function edit (query) {
    return request({
        url: "book-group-rules",
        method: "put",
        data: query
    });
}
