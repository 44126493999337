<template>
    <v-app id="inspire">

        <v-app-bar app color="#1A3958" dark class="elevation-0">

            <v-toolbar-side-icon>
                <v-img src="@assets/logo_icon.png" width="25"></v-img>
            </v-toolbar-side-icon>
            
            <v-toolbar-title class="ml-4">Position Keeper</v-toolbar-title>

            <CurrentLiveTime :name="'London'" :timeZone="0" :DST="false"/>
            <CurrentLiveTime :name="'MT4'" :timeZone="2" :DST="false"/>
            <CurrentLiveTime :name="'Beijing'" :timeZone="7" :DST="true"/>
            <CurrentLiveTime :name="'New York'" :timeZone="7" :DST="true"/>
            <CurrentLiveTime :name="'Sydney'" :timeZone="10" :DST="true"/>

            <v-spacer></v-spacer>

            <v-menu offset-y :nudge-top="0" open-on-hover tile :close-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on" style="color: white;" class="mr-20">
                        Hi, {{ displayName }} <v-icon>mdi-account</v-icon>
                    </a>
                </template>
                <v-list class="pl-0 pr-0 pt-0 pb-0" style="text-align: left; border-radius: 0px;">

                    <v-list-item dense to="/account/profile">
                        <v-list-item-icon class="mr-0"><v-icon small>mdi-face-man-profile</v-icon></v-list-item-icon>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>

                    <v-list-item dense to="/account/config" v-if="loadConfig == 1">
                        <v-list-item-icon class="mr-0"><v-icon small>mdi-cog-outline</v-icon></v-list-item-icon>
                        <v-list-item-title>Config</v-list-item-title>
                    </v-list-item>

                    <v-list-item dense to="/account/management" v-if="loadManagement == 1">
                        <v-list-item-icon class="mr-0"><v-icon small>mdi-folder-multiple</v-icon></v-list-item-icon>
                        <v-list-item-title>Management</v-list-item-title>
                    </v-list-item>

                      <v-divider></v-divider>

                    <v-list-item dense @click.stop="logout()">
                        <v-list-item-icon class="mr-0"><v-icon small>mdi-logout</v-icon></v-list-item-icon>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-app-bar>
        <!-- <LogPanel /> -->

        <!-- <LogDialog /> -->

        <v-main style="padding-top:10px; padding-left: 0px; padding-right: 0px;">
            <v-container fluid id="global-container" class="pt-0">
                <HeaderMenu />

                <router-view></router-view>

                  <div class="text-center">
                    <v-overlay :value="this.$store.state.overlay">
                        <v-progress-circular indeterminate :size="70" :width="7" color="primary"></v-progress-circular>
                        <p>Loading Data...</p>
                    </v-overlay>
                </div>

            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import HeaderMenu from "@components/HeaderMenu";
// import LogPanel from "@components/ComponentGlobalLog";
import CurrentLiveTime from "@components/ComponentCurrentLiveTime";
import { permission } from "@components/mixins/permission";

import { mapState } from "vuex";

export default {
    name: "Layout",
    components: { CurrentLiveTime, HeaderMenu },
    mixins: [permission],
    data: () => ({
        loadManagement: 0,
        loadConfig: 0,
        drawer: false,
        alertDrawer: false,
        channelList: [],
        consoleDrawer: false,
        selectedAlertPanel: 1,
        loadRing: true,
        viewedBellCount: 0
    }),
    computed: {
        ...mapState([
            "alertBadage"
        ]),
        displayName () {
            return localStorage.getItem("displayName");
        },
        bellCount () {
            return Math.abs(this.alertBadage.total - this.viewedBellCount);
        }
    },
    watch: {
        alertDrawer (nv, ov) {
            if (nv) {
                this.$store.commit("alertCount");
                this.channelList = JSON.parse(localStorage.getItem("alertChannel"));
                localStorage.setItem("viewedeBllCount", this.alertBadage.total);
                this.viewedBellCount = this.alertBadage.total;
                // console.log(this.alertBadage.total)
            }
        },
        "alertBadage.total" (nv, ov) {
            if (this.alertDrawer) {
                this.viewedBellCount = this.alertBadage.total;
                localStorage.setItem("viewedeBllCount", this.alertBadage.total);
            }
        }
    },
    methods: {
        /**
         * Logout user account
         *
         * @return  {[type]}  [return description]
         */
        logout () {
            this.$store.commit("alertUnsubscribe");

            localStorage.clear();
            this.$router.push({ name: "Signin" });
            this.$store.state.loginStatus = -1;
        },
        changeDrawTab (key) {
            this.selectedAlertPanel = key;
            this.$store.commit("alertCacheAllInitial", 50);
        },
        toggleAlertDrawer () {
            // if (!this.alertDrawer) this.$store.commit("alertCountClear");
            this.alertDrawer = !this.alertDrawer;

            // console.log(this.$store.state.globalAlertStreamExpansion.length);

            if (this.$store.state.globalAlertStreamExpansion === null) {
                this.$store.commit("toggleGlobalAlertStreamExpansion", 0);
            } else {
                this.$store.commit("toggleGlobalAlertStreamExpansion", null);
            }
            // this.$store.commit("toggleGLobalAlertStream", true);
            this.$store.commit("alertCacheAllInitial", 50);
        },
        darkMode () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        }
    },
    mounted () {
        const permission = JSON.parse(localStorage.getItem("bp")).frontPermission;

        if (!Object.prototype.hasOwnProperty.call(permission, "alert")) this.loadRing = false;

        const accountChild = this.getChild("account");

        if (accountChild.includes("management")) this.loadManagement = 1;
        if (accountChild.includes("config")) this.loadConfig = 1;

        this.$store.commit("alertCacheAllInitial", 50);
        this.viewedBellCount = JSON.parse(localStorage.getItem("viewedeBllCount"));
    }
};
</script>
<style scoped>

</style>
