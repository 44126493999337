<template>
    <div class="ml-10">
        <p class="mt-4" :id='timezoneName'>
            {{ timezoneName }}: {{ currentTime }}
        </p>
    </div>
</template>
<script>

import { helper } from "@components/mixins/helper";

export default {
    components: {},
    mixins: [helper],
    props: {
        name: "",
        timeZone: 0,
        DST: false
    },
    data () {
        return {
            timezoneName: "",
            currentTime: null
        };
    },
    mounted () {
        this.timezoneName = this.name;
        setInterval(() => {
            const localtime = new Date();
            const copiedDate = new Date(localtime.getTime());

            if (this.timezoneName === "Sydney") this.currentTime = localtime.toLocaleString(undefined, { timeZone: "Australia/Sydney" });
            if (this.timezoneName === "MT4") {
                const offerset = this.getMT4OfferSet(localtime);
                this.currentTime = (new Date(localtime.setHours(copiedDate.getHours() + offerset))).toLocaleString();
            }

            if (this.timezoneName === "Beijing") this.currentTime = localtime.toLocaleString(undefined, { timeZone: "Asia/Shanghai" });
            if (this.timezoneName === "New York") this.currentTime = localtime.toLocaleString(undefined, { timeZone: "America/New_York" });

            if (this.timezoneName === "London") {
                var localdate = new Date();
                var offset = localdate.getTimezoneOffset() / 60;
                localdate.setHours(localdate.getHours() + offset);
                this.currentTime = localdate.toLocaleString();
            }
        }, 1000);
    },
    methods: {

    },
    destroyed () {
        // clearInterval(this.interval);
    }
};
</script>
