import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import UserManagement from "@store/modules/user-management";
import SearchHistory from "@store/modules/search-history";
import BookGroupRules from "@store/modules/book-group-rules";
import Alert from "@store/modules/alert";
import BigLot from "@store/modules/alert/big-lot";
import ProfitTaker from "@store/modules/alert/profit-taker";
import LockingPosition from "@store/modules/alert/locking-position";
import TradeOnCredit from "@store/modules/alert/trade-on-credit";
import FastTrade from "@store/modules/alert/fast-trade";
import VolumeAlert from "@store/modules/alert/volume-alert";
import SameDirection from "@store/modules/alert/same-direction";
import WithdrawDeposit from "@store/modules/alert/withdraw-deposit";
import Login from "@store/modules/login/login";
import LoginSummaryDialog from "@store/modules/components/login-summary-dialog";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        Login: Login,
        LoginSummaryDialog: LoginSummaryDialog,
        SearchHistory: SearchHistory,
        UserManagement: UserManagement,
        BookGroupRules: BookGroupRules,
        BigLot: BigLot,
        ProfitTaker: ProfitTaker,
        LockingPosition: LockingPosition,
        TradeOnCredit: TradeOnCredit,
        FastTrade: FastTrade,
        SameDirection: SameDirection,
        VolumeAlert: VolumeAlert,
        WithdrawDeposit: WithdrawDeposit,
        Alert: Alert,
    },
    state: {
        alertInterval: 30000,
        isValidJson: false,
        tickConfig: {
            visible: false,
            broker: "",
            name: "",
            search: ""
        },
        hedgeMap: {
            1: "H1",
            2: "H902",
            3: "H702",
            4: "RB-B-HEDGE",
            10: "H602"
        },
        wssUrlMap: {
        },
        specialAlertLike: [],
        barrageCount: 0,
        barrageIsShow: false,
        barrageList: [],
        barrageLoop: false,
        alertNotificationSettings: [],
        alertAll: [],
        rootUser: 0,
        listBroker: [],
        selectedBroker: "",
        selectedBrokerId: -1,

        permissionSuper: { broker: [{ broker_id: 1, broker: "AUGS", book: ["*"], bookExcept: [] }, { broker_id: 2, broker: "SUPER", book: ["*"], bookExcept: [] }, { broker_id: 3, broker: "PROSPERO", book: ["*"], bookExcept: [] }], frontPermission: { book: { child: ["positionKeeper", "positionChart", "nexposureChart"] }, search: { child: ["inputBroker"] }, alert: { child: [], channel: ["big_lot", "mandate", "profit_taker", "locking_position", "trade_on_credit", "large_exposure", "fast_trade", "watch_list", "tick_check", "same_direction", "volume_alert"], bookExcept: ["BUSC"] }, validation: { child: ["net"] }, tools: { child: ["group", "userPerformance"] }, user: { child: [] } }, whitelist: ["*"] },
        permissionDemo: { broker: [{ broker_id: 1, broker: "AUGS", book: ["TEST", "T"], bookExcept: [] }], frontPermission: { book: { child: ["positionKeeper"] }, search: {}, alert: { child: [], channel: ["big_lot"], bookExcept: ["BUSC"] } }, whitelist: ["*"] },
        permissionNormal: { broker: [{ broker_id: 1, broker: "AUGS", book: ["*"], bookExcept: [] }], frontPermission: { book: { child: ["positionKeeper", "positionChart", "nexposureChart"] }, search: { child: ["inputBroker"] }, alert: { child: [], channel: ["big_lot", "mandate", "profit_taker", "locking_position", "trade_on_credit", "large_exposure", "fast_trade", "watch_list", "tick_check", "same_direction", "volume_alert"], bookExcept: ["BUSC"] }, whitelist: ["*"] } },

        disalbeNotificationAlert: [],
        loginBtnLoading: false,
        loginStatus: -1,
        loginMsg: "",
        socket: null,
        connectId: null,
        snackbar: false,
        snackbarbtn: "Close",
        snackbarbtnid: 'snackbar',
        snackmsg: "",
        snacktimeout: 2000,
        overlay: false,
        snackclosecolor: "white",
        snackcolor: "",
        brokerId: -1,
        jwtToken: null,
        assignUser: [],
        brokerMap: [
            { broker: "AUGS", broker_id: 1 },
            { broker: "SUPER", broker_id: 2 },
            { broker: "PROSPERO", broker_id: 3 }
            // { "broker": "DEMO", "broker_id": 99 },
        ],
        symbolRules: ["-", "c", "s", "."],
        symbolEndfix: ["-s", "-e", "-r", "s", "c", ".", "-i", "-k", "-u", "-h", "-y", "-p", "-std", "-ecn", "-inx", ""],
        alertBadage: {
            total: 0,
            big_lot: 0,
            mandate: 0,
            profit_taker: 0,
            locking_position: 0,
            trade_on_credit: 0,
            large_exposure: 0,
            fast_trade: 0,
            watch_list: 0,
            tick_check: 0,
            same_direction: 0,
            volume_alert: 0,
            withdraw_deposit: 0
        },
        globalAlertStreamDialog: false,
        globalAlertStreamExpansion: null,
        alertUiPosition: {
            big_lot: 488,
            mandate: 488,
            profit_taker: 813,
            locking_position: 813,
            trade_on_credit: 1146,
            large_exposure: 1146,
            fast_trade: 1475,
            watch_list: 1475,
            tick_check: 0,
            same_direction: 0,
            volume_alert: 0,
            withdraw_deposit: 0
        },
        alertNotificationShowItem: {
            big_lot: {
                alert_name: false,
                broker: false,
                login: true,
                book: true,
                symbol: true,
                volume: true,
                order: false,
                time: false,
                direction: false,
                group: false,
                message: false,
                orderOpenTime: true
            },
            mandate: {
                alert_name: false,
                broker: false,
                symbol: true,
                book: true,
                type: true,
                currentValue: true,
                time: false,
                message: false,
                threshold: false
            },
            profit_taker: {
                alert_name: false, broker: false, login: true, book: true, symbol: true, profitToday: true, time: false, message: false
            },
            locking_position: {
                alert_name: false, broker: false, login: true, book: true, symbol: true, lockingPosition: true, time: false, message: false
            },
            trade_on_credit: {
                alert_name: false, broker: false, login: true, book: true, balance: true, credit: true, equity: true, time: false, message: false
            },
            large_exposure: {
                alert_name: false, broker: false, login: true, symbol: true, book: true, exposure: true, message: false, time: false
            },
            fast_trade: {
                alert_name: false, broker: false, login: true, book: true, symbol: true, volume: true, time: false, group: false, message: false, ticket: false
            },
            watch_list: {
                alert_name: false,
                broker: false,
                login: true,
                direction: true,
                symbol: true,
                price: true,
                orderTicket: true,
                book: false,
                group: false,
                message: false,
                time: false
            },
            tick_check: {
                alert_name: false, broker: false, lastUpdateTime: true, message: false, now: true, symbol: true, time: false
            },
            same_direction: {
                alert_name: false,
                broker: false,
                login: true,
                book: true,
                symbol: true,
                volume: true,
                event: true,
                totalVolume: true,
                message: false,
                order: true,
                time: false
            },
            volume_alert: {
                alert_name: false, broker: false, book: true, login: true, message: false, profit: true, volumeToday: true, time: false
            },
            withdraw_deposit: {
                alert_name: false, broker: false, amount: true, book: true, login: true, message: false, order: true, thredshold_amount: false, type: true, time: false
            }
        },
        alertHeader: {
            big_lot: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Open Time", value: "orderOpenTime", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Vol", value: "volume", align: "left" },
                { text: "Ticket", value: "order", align: "left" },
                { text: "Dir", value: "direction", align: "left" },
                { text: "Group", value: "group", align: " d-none" },
                { text: "Msg", value: "message", align: " d-none" }
            ],
            mandate: [
                { text: "Time", value: "time", align: "left" },
                { text: "Book/Group", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Type", value: "type", align: "left" },
                { text: "Value", value: "currentValue", align: "left" },
                { text: "Threshold", value: "threshold", align: "left" },
                { text: "Msg", value: "message", align: " d-none" }
            ],
            profit_taker: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "PNL", value: "profitToday", align: "left" },
                { text: "Msg", value: "message", align: " d-none" }
            ],
            locking_position: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Lock Position", value: "lockingPosition", align: "left" },
                { text: "Msg", value: "message", align: " d-none" }
            ],
            trade_on_credit: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Balance", value: "balance", align: "left" },
                { text: "Credit", value: "credit", align: "left" },
                { text: "Equity", value: "equity", align: "left" },
                { text: "Msg", value: "message", align: " d-none" }
            ],
            large_exposure: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Exposure", value: "exposure", align: "left" },
                { text: "message", value: "message", align: " d-none" }
            ],
            fast_trade: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Volume", value: "volume", align: "left" },
                { text: "Group", value: "group", align: "left" },
                { text: "Ticket", value: "ticket", align: "left" },
                { text: "Message", value: "message", align: " d-none" }
            ],
            watch_list: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Direction", value: "direction", align: "left" },
                { text: "Group", value: "group", align: "left" },
                { text: "Price", value: "price", align: "left" },
                { text: "Ticket", value: "orderTicket", align: "left" },
                { text: "Message", value: "message", align: " d-none" }
            ],
            tick_check: [
                // // { text: "Time", value: "time", align: " d-none"},
                // { text: "Symbol", value: "symbol", align: "left" },
                // { text: "Now(MT4)", value: "now", align: "left" },
                // { text: "Gap", value: "gap", align: "left" },
                // { text: "Last Update Time(MT4)", value: "lastUpdateTime", align: "left" },
                // // { text: "Local Time", value: "local_time", align: " d-none" },
                // { text: "Threshold", value: "threshold", align: "left" },
                
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Now(MT4)", value: "now", align: "left" },
                { text: "Last Update Time(MT4)", value: "lastUpdateTime", align: "left" },
                { text: "Gap", value: "gap", align: "left" },
                { text: "Threshold", value: "threshold", align: "left" },
                { text: "Server", value: "server", align: "left" }

                // { text: "Symbol", value: "symbol", align: "left" },
                // { text: "Last Update", value: "lastUpdateTime", align: "left" },
                // { text: "Current", value: "now", align: "left" },
                // { text: "Gap", value: "gap", align: "left" },
                // { text: "Message", value: "message", align: "left" },
            ],
            same_direction: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Volume", value: "volume", align: "left" },
                { text: "Ticket", value: "order", align: "left" },
                { text: "Event", value: "event", align: "left" },
                { text: "Total Volume", value: "totalVolume", align: "left" },
                { text: "Message", value: "message", align: " d-none" }
            ],
            volume_alert: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Profit", value: "profit", align: "left" },
                { text: "Vol Today", value: "volumeToday", align: "left" },
                { text: "message", value: "message", align: " d-none" }
            ],
            withdraw_deposit: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Order", value: "order", align: "left" },
                { text: "Type", value: "type", align: "left" },
                { text: "Amount", value: "amount", align: "left" },
                { text: "Thredshold Amount", value: "thredshold_amount", align: "left" },
                { text: "message", value: "message", align: " d-none" }
            ]
        },
        currentTimeServer: null,
        allowJsonEditor: 0,
        versionFlag: false
    },
    getters: {
        getCurrentTimeServer (state) {
            return state.currentTimeServer;
        }
    },
    mutations,
    actions: {
    },
});

export default store;
