import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../Layout.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "RootHome",
        component: Layout,
        children: [
            {
                path: "",
                name: "Home",
                component: () => import("../views/Home.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Book Monitor", disabled: true, href: "/" }
                    ],
                    permission: "book"
                }
            }, {
                path: "lp",
                name: "LP",
                component: () => import("../views/lp/lp.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "LP", disabled: true, href: "/" }
                    ],
                    permission: "lp"
                }
            }, 
            
            // {
            //     path: "summary-symbol/:broker/:symbol/:book",
            //     name: "SummarySymbol",
            //     component: () => import("../views/book/SummarySymbol.vue"),
            //     meta: {
            //         requiresAuth: true,
            //         isAdmin: false,
            //         bread: [
            //             { text: "Home", disabled: false, href: "/#/" },
            //             { text: "Symbol Summary", disabled: true, href: "/#/summary-symbol" }
            //         ],
            //         permission: 2
            //     }
            // },
            // {
            // 	path: 'alert',
            // 	name: 'Alert',
            // 	component: () => import('../views/alert/Alert.vue'),
            // 	meta: {
            // 		requiresAuth: true, isAdmin: false,
            // 		bread: [
            // 			{ text: 'Alert', disabled: true, href: '/#/alert' }
            // 		], permission: 'alert'
            // 	}
            // },
            {
                path: "settings",
                name: "Settings",
                component: () => import("../views/settings/Settings.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Home", disabled: true, href: "/" },
                        { text: "Settings", disabled: true, href: "/settings" }
                    ],
                    permission: "settings"
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/validation",
        name: "Validate",
        component: Layout,
        children: [
            {
                path: "net",
                name: "ValidateNet",
                component: () => import("../views/validate/ValidateNet.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Validation", disabled: true, href: "/#/" },
                        { text: "Net", disabled: false, href: "/#/validation/net" }

                    ],
                    permission: ["validation", "net"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/server",
        name: "Server",
        component: Layout,
        children: [
            {
                path: "log",
                name: "ServerLog",
                component: () => import("../views/server/Log.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Server", disabled: true, href: "/#/" },
                        { text: "Log", disabled: false, href: "/#/server/log" }

                    ],
                    permission: ["server", "log"]
                }
            }, {
                path: "performance",
                name: "ServerPerformance",
                component: () => import("../views/server/Performance.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Server", disabled: true, href: "/#/" },
                        { text: "Performance", disabled: false, href: "/#/server/performance" }

                    ],
                    permission: ["server", "performance"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/alert",
        name: "Alert",
        component: Layout,
        children: [
            {
                path: "overview",
                name: "AlertOverview",
                component: () => import("../views/alert/Overview.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Alert", disabled: true, href: "/#/" },
                        { text: "Overview", disabled: false, href: "/#/alert/overview" }

                    ],
                    permission: ["alert", "overview"]
                }
            }, {
                path: "history",
                name: "AlertHistory",
                component: () => import("../views/alert/History.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Alert", disabled: true, href: "/#/" },
                        { text: "History", disabled: false, href: "/#/alert/history" }

                    ],
                    permission: ["alert", "history"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
    {
        path: "/account",
        name: "Account",
        component: Layout,
        children: [
            {
                path: "profile",
                name: "AccountProfile",
                component: () => import("../views/account/Profile.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        { text: "Profile", disabled: false, href: "/#/account/profile" }

                    ],
                    permission: ["account", "profile"]
                }
            }, {
                path: "config",
                name: "AccountConfig",
                component: () => import("../views/account/Config.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        { text: "Config", disabled: false, href: "/#/account/config" }

                    ],
                    permission: ["account", "config"]
                }
            }, {
                path: "management",
                name: "AccountManagement",
                component: () => import("../views/account/Management.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Account", disabled: true, href: "/#/" },
                        { text: "Account Management", disabled: true, href: "/account/management" }
                    ],
                    permission: ["account", "management"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/system",
        name: "System",
        component: Layout,
        children: [
            {
                path: "group",
                name: "SystemGroup",
                component: () => import("../views/system/Group.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Group", disabled: false, href: "/#/system/group" }
                    ],
                    permission: ["system", "group"]
                }
            }, {
                path: "book-group-rules",
                name: "SystemBookGroupRules",
                component: () => import("../views/system/BookGroupRules.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Book Group Rules", disabled: false, href: "/#/system/book-group-rules" }
                    ],
                    permission: ["system", "bookGroupRules"]
                }
            }, {
                path: "snapshot",
                name: "SystemSnapshot",
                component: () => import("../views/system/Snapshot.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Snapshot", disabled: false, href: "/#/system/snapshot" }
                    ],
                    permission: ["system", "snapshot"]
                }
            }, {
                path: "symbol-rule",
                name: "SystemSymbolRule",
                component: () => import("../views/system/SymbolRule.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Symbol Rule", disabled: false, href: "/#/system/symbol-rule" }
                    ],
                    permission: ["system", "symbolRule"]
                }
            }, {
                path: "tick",
                name: "Tick",
                component: () => import("../views/system/Tick.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Tick", disabled: false, href: "/#/system/tick" }
                    ],
                    permission: ["system", "tick"]
                }
            }, {
                path: "ticklive",
                name: "TickLive",
                component: () => import("../views/system/TickLive.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "TickLive", disabled: false, href: "/#/system/ticklive" }
                    ],
                    permission: ["system", "ticklive"]
                }
            }, {
                path: "alert-configuration",
                name: "SystemAlertConfiguration",
                component: () => import("../views/system/AlertConfiguration.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Alert Configuration", disabled: false, href: "/#/system/alert-configuration" }
                    ],
                    permission: ["system", "alertConfiguration"]
                }
            },
            {
                path: "notification",
                name: "SystemNotification",
                component: () => import("../views/system/Notification.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "System", disabled: true, href: "/#/" },
                        { text: "Notification", disabled: false, href: "/#/system/notification" }

                    ],
                    permission: ["system", "notification"]
                }
            }
            // {
            // 	path: 'user-performance',
            // 	name: 'ToolUserPerformance',
            // 	component: () => import('../views/tool/UserPerformance.vue'),
            // 	meta: { requiresAuth: true, isAdmin: false,
            // 		bread: [
            // 			{ text: 'Tool', disabled: true, href: '/#/' },
            // 			{ text: 'User Performance', disabled: false, href: '/#/tool/user-performance' },

            // 		], permission: ['tools', 'userPerformance']
            // 	}
            // }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/risk",
        name: "Risk",
        component: Layout,
        children: [
            {
                path: "prometheus",
                name: "RiskPrometheus",
                component: () => import("../views/risk/Prometheus.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Risk", disabled: true, href: "/#/" },
                        { text: "Prometheus", disabled: false, href: "/#/risk/prometheus" }

                    ],
                    permission: ["risk", "prometheus"]
                }
            },
            {
                path: "notification",
                name: "RiskNotification",
                component: () => import("../views/risk/Notification.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Risk", disabled: true, href: "/#/" },
                        { text: "Notification", disabled: false, href: "/#/risk/notification" }

                    ],
                    permission: ["risk", "notification"]
                }
            }
        ]
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/search",
        name: "Search",
        component: Layout,
        children: [
            {
                path: "",
                name: "SearchHome",
                component: () => import("../views/search/Search.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" }
                    ],
                    permission: "search"
                }
            },
            {
                path: "raw-history",
                name: "SearchRawHistory",
                component: () => import("../views/search/RawHistory.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" },
                        { text: "Raw History", disabled: true, href: "/#/search/raw-history" }
                    ],
                    permission: ["search", "rawHistory"]
                }
            },
            {
                path: "transaction",
                name: "SearchTransaction",
                component: () => import("../views/search/Transaction.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" },
                        { text: "Transaction", disabled: true, href: "/#/search/transaction" }
                    ],
                    permission: ["search", "transaction"]
                }
            },
            {
                path: "profit-loss",
                name: "SearchProfitLoss",
                component: () => import("../views/search/ProfitLoss.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" },
                        { text: "Profit Loss", disabled: true, href: "/#/search/profit-loss" }
                    ],
                    permission: ["search", "profitLoss"]
                }
            },
            {
                path: "volume",
                name: "SearchVolume",
                component: () => import("../views/search/Volume.vue"),
                meta: {
                    requiresAuth: true,
                    isAdmin: false,
                    bread: [
                        { text: "Search", disabled: true, href: "/#/search" },
                        { text: "Volume", disabled: true, href: "/#/search/volume" }
                    ],
                    permission: ["search", "volume"]
                }
            }
        ]// route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/signin",
        name: "Signin",
        component: () => import("../views/account/Signin.vue")
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/forget-password",
        name: "ForgetPassword",
        component: () => import("../views/account/ForgetPassword.vue")
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }, {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("../views/account/ResetPassword.vue")
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }
];

const router = new VueRouter({
  	routes
});

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

export default router;
