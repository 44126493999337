import * as apiBook from "@components/api/pk/book";
import * as apiGroup from "@components/api/pk/group";
import * as apiSymbol from "@components/api/pk/symbol";
import * as apiTrade from "@components/api/pk/trades";
import * as apiSearchProfitLoss from "@components/api/pk/search-profit-loss";
import * as apiSearchTransaction from "@components/api/pk/search-transaction";
import * as apiSearchVolume from "@components/api/pk/search-volume";

const state = {
    filterSymbolLists: [],
    filterGroupLists: []
};

const actions = {
    /**
     * Api call to fetch symbol
     * @param   {[type]}  commit  [commit description]
     * @return  {[type]}          [return description]
     */
    loadSymbol({ commit }) {
        apiSymbol.fetch().then((res) => {
            const data = res.data.data;
            commit("UPDATE_FILTER_SYMBOL_LISTS", data);
        });
    },
    /**
     * Api call to fetch group
     * @param   {[type]}  commit  [commit description]
     * @return  {[type]}          [return description]
     */
    loadGroup({ commit }) {
        apiGroup.fetch(params).then((res) => {
            commit("UPDATE_FILTER_GROUP_LISTS", res);
        });
    }
};

const mutations = {
    /**
     * Update filter symbol lists
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_FILTER_SYMBOL_LISTS(state, data) {
        state.filterSymbolLists = data;
    },
    /**
     * Update filter group lists
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_FILTER_GROUP_LISTS(state, data) {
        state.filterGroupLists = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}