import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
import JsonExcel from "vue-json-excel";
import ECharts from "vue-echarts";
import VueCodemirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import common from "@assets/js/common";

Vue.use(common);
// import { vueBaberrage } from "vue-baberrage";
// Vue.use(vueBaberrage);

// you can set default global options and events when Vue.use
Vue.use(VueCodemirror, {
    options: { tabSize: 4, theme: "base16-dark", lineNumbers: true, line: true, mode: { name: "javascript", json: true } },
    events: ["scroll"]
});

// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Toast, { transition: "Vue-Toastification__bounce", maxToasts: 2, newestOnTop: true });

Vue.config.productionTip = false;
Vue.use(DatetimePicker);
// Vue.use(VueToast);

// register component to use
Vue.component("v-chart", ECharts);
Vue.component("downloadExcel", JsonExcel);

global.ENCRYPT_KEY = "bfjfunds";

global.wssNode1Url = "wss://wss.sigmatm.com.au:9090/positionsByGroup?platform=";
global.wssNode2Url = "wss://wss-node2.sigmatm.com.au:9090/positionsByGroup?platform=";

// global.SOCKETIOURL = "http://127.0.0.1/";
// global.SOCKETIOURL = "https://api-pk-socket.sigmarisk.xyz/";
global.SOCKETIOURL = "https://api-pk-socket.sigmatm.com.au/";

// global.SOCKETIOURL = 'http://127.0.0.1:9999/';
// global.SOCKETIOURL = 'ws://47.89.190.114:8080/positionsByGroup';
// global.SOCKETIOURL = 'https://pk-socketio.sigmatm.com.au/';
// global.SOCKETIOURL = "https://api-pk-dev.sigmarisk.com.au/api/v1/";
// global.APIURL = "https://api-pk.sigmarisk.com.au/api/v1/";
// global.APIURL = "http://127.0.0.1:4444/api/v1/";
// global.APIURL = "https://api-pk.sigmarisk.xyz/api/v1/"
// global.APIURL = "https://dev-api-pk.sigmarisk.xyz/api/v1/"
global.APIURL = "https://api-pk.sigmarisk.xyz/api/v1/"

// global.APIURL = 'http://192.168.0.110:4444/api/v1/'

router.beforeEach((to, from, next) => {
    // 创建socket
    store.commit("socketBuild");

    // 计算小铃铛警报的数量
    store.commit("alertCount");

    // 初始化每个broker的socket连接
    store.commit("initialWssUrl");

    // 获取前n的警报
    store.commit("alertCacheAllInitial", 50);

    // 路有逻辑
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // checkVersion();

        localStorage.setItem("currentPath", to.path);

        // 如果没有jwt验证跳转登陆页面
        if (localStorage.getItem("bst") === null) {
            next({ path: "/signin", query: { redirect: to.fullPath } });
        } else {
            // 判断permission是字符串还是数组，数组的话就去一级菜单的children里面找有没有相同的permission name
            const bp = JSON.parse(localStorage.getItem("bp"));
            const data = bp.frontPermission;

            // if (bp.hasOwnProperty("allowJsonEditor")) {
            if (Object.prototype.hasOwnProperty.call(bp, "allowJsonEditor")) {
                store.state.allowJsonEditor = bp.allowJsonEditor;
            }

            // 获取broker列表
            store.state.brokerMap = bp.broker;

            if (to.meta.permission) {
                const permission = to.meta.permission;

                if (typeof permission === "string") {
                    if (data[permission]) {
                        // store.commit("alertSubscribe");
                        next();
                        return;
                    } else {
                        store.state.snackcolor = "red";
                        store.state.snackmsg = "Error";
                        store.state.snackbar = "true";
                        next({ path: from.fullPath });
                        return;
                    }
                }

                if (Array.isArray(permission)) {
                    const parent = to.meta.permission[0];

                    // console.log("Router To: ", to);

                    if (to.path !== "/alert/overview") store.commit("alertSubscribe");

                    // console.log(data[parent]);

                    if (data[parent] && data[parent].child.includes(to.meta.permission[1])) {
                        next();
                    } else {
                        console.log("Access Herer 2");
                        store.state.snackcolor = "red";
                        store.state.snackmsg = "Error";
                        store.state.snackbar = "true";
                        next({ path: from.fullPath });
                    }
                }
            }
        }
    } else {
        next();
    }
});

router.afterEach((to, from, next) => {

});

setTimeout(() => {
    checkZoomLevel();
}, 1000);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");

function checkVersion() {
    axios
        .get("version.json", { headers: { "Cache-Control": "no-cache" } })
        .then((res) => {
            // 最新版本
            const version = res.data.version;
            console.log("Checked Version: ", version);

            // 当前版本
            const clientVersion = localStorage.getItem("version");
            if (clientVersion === null) {
                localStorage.setItem("version", version);
                return;
            }
            console.log("Version Compare Result: ", version !== clientVersion);
            if (version !== clientVersion) {
                store.state.versionFlag = true;
                setTimeout(() => {
                    location.reload();
                }, 500);
            } else {
                store.state.versionFlag = false;
            }
        });
}

function checkZoomLevel () {

    if (window.matchMedia("(max-width: 1280px)").matches) {
        document.getElementsByClassName('v-application--wrap')[0].style.zoom = "70%";
        // console.log("Hello 1");
        // let dom = document.getElementsByClassName('v-application--wrap');
        // for (let i = 0; i < dom.length; i++) {
        //     dom[i].style.zoom = ("70%");
        //     break;
        // }
    }
    
    if (window.matchMedia("(max-width: 1440px)").matches) {

        console.log("Hello 2");
        document.getElementsByClassName('v-application--wrap')[0].style.zoom = "75%";
        // for (let i = 0; i < dom.length; i++) {
        //     dom[i].style.zoom = ("70%");
        //     break;
        // }
        // document.body.style.zoom = ("70%");
    }

    // if (window.matchMedia("(max-width: 1920px)").matches) {
    //     document.body.style.zoom = ("90%");
    // }
}
