import io from "socket.io-client";
import axios from "axios";
import store from "./store";
import * as CryptoJS from "crypto-js";
import _ from "lodash";
import { MESSAGE_TYPE } from "vue-baberrage";

const mutations = {
    /**
     * Change snackbar to show message toast
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_SNACKBAR(state, data) {
        state.snackmsg = data.message;
        state.snackcolor = data.color;
        state.snackbarbtn = data.btnName;
        state.snackbar = data.status;
        state.snacktimeout = data.timeout;
    },
    /**
     * Update is valid json value
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  value  [value description]
     * @return  {[type]}         [return description]
     */
    UPDATE_IS_VALID_JSON(state, value) {
        state.isValidJson = value;
    },
    /**
     * Check if data is valid json or not
     * @param   {[type]}  data  [data description]
     * @return  {[type]}        [return description]
     */
    IS_VALID_JSON(state, data) {
        console.log("IS_VALID_JSON: ", data);
        try {
            if (typeof data === "object" && data !== null) {
                state.isValidJson = true;
            } else {
                JSON.parse(data);
                state.isValidJson = true;
            }
        } catch (e) {
            state.isValidJson = false;
        }
    },
    assignAlertHeader (state, layoutTable) {
        if (layoutTable === undefined) return;
        if (layoutTable === null) return;
        for (const alert in state.alertHeader) {
            if (layoutTable.hasOwnProperty(alert)) {
                state.alertHeader[alert] = layoutTable[alert];
            }
        }
    },
    /**
     * 改变alert表格header显示
     * @param  {[type]} state  [description]
     * @param  {[type]} header [description]
     * @param  {[type]} alert  [description]
     * @param  {[type]} status [description]
     * @return {[type]}        [description]
     */
    toggleAlertHeader (state, data) {
        const status = data.status;
        const alert = data.alert;
        const header = data.header;

        for (let i = 0; i < state.alertHeader[alert].length; i++) {
            if (state.alertHeader[alert][i].value == header) {
                state.alertHeader[alert][i].align = status;
                break;
            }
        }
    },
    toggleGlobalAlertStreamExpansion (state, data) {
        console.log("Toggle Data: ", data);
        state.globalAlertStreamExpansion = data;
    },
    toggleGLobalAlertStream (state, status) {
        state.globalAlertStreamDialog = status;
    },
    setServerTime (state, time) {
        state.currentTimeServer = time;
    },
    globalLoadingHandler (state, status) {
        state.overlay = status;
    },
    /**
     * Open tick config between different component (ComponentGlobalNotificationAlert.vue & Alert.vue)
     * @param   {[type]}  state   [state description]
     * @param   {[type]}  alert   [alert description]
     * @param   {[type]}  broker  [broker description]
     * @return  {[type]}          [return description]
     */
    triggerTickConfig (state, data) {
        console.log("Data: ", data);
        state.tickConfig.visible = data.status;
        state.tickConfig.name = data.name;
        state.tickConfig.broker = data.broker;
        if (data.search != "") state.tickConfig.search = "-1";
    },
    /**
     * broker socket连接的map
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    initialWssUrl (state) {
        state.wssUrlMap = {
            1: "wss://wss-node2.sigmatm.com.au:9090/positionsByGroup?platform=",
            10: "wss://fpg.sigmarisk.xyz:9090/positionsByGroup?platform=",
            8: "wss://wss-node2.sigmatm.com.au:9090/positionsByGroup?platform=",
            4: "wss://wss-node2.sigmatm.com.au:9090/positionsByGroup?platform=",
            3: "wss://prospero.sigmarisk.xyz:9090/positionsByGroup?platform=",
            2: "wss://super.sigmarisk.xyz:9090/positionsByGroup?platform=",
        };

        state.hedgeMap = {
            1: "H1",
            2: "H902",
            3: "H702",
            4: "RB-B-HEDGE",
            10: "H602"
        };
    },
    alertTick (state, brokerId) {
    },
    /**
     * 取消订阅
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    alertUnsubscribe (state) {
        const alertChannel = JSON.parse(localStorage.getItem("alertChannel"));
        for (const item of state.brokerMap) {
            const tickChannel = "TickAlert:" + item.broker_id.toString();
            state.socket.removeListener(tickChannel);

            for (const channel of alertChannel) {
                if (channel == "tick_check") continue;
                const selectedChannel = item.broker + "-AlertsChannel:" + channel;
                state.socket.removeListener(selectedChannel);
            }
        }
    },
    /**
     * 全局alert 订阅方法
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    alertSubscribe (state) {
        const alertChannel = JSON.parse(localStorage.getItem("alertChannel"));

        for (const item of state.brokerMap) {
            const tickChannel = "TickAlert:" + item.broker_id.toString();
            // console.log("Subscribe Channel ", tickChannel);
            state.socket.removeListener(tickChannel);
            state.socket.on(tickChannel, (data) => {
                // console.log("Tick Data: ", data);
            });

            // console.log("End Subscribe Tick Alert ", item["broker_id"]);
            for (const channel of alertChannel) {
                if (channel == "tick_check") continue;

                const selectedChannel = item.broker + "-AlertsChannel:" + channel;
                // console.log("Selected Channel: ", selectedChannel);
                state.socket.removeListener(selectedChannel);

                state.socket.on(selectedChannel, (data) => {
                    // console.log("Hello Alert ==> ", data);

                    data = JSON.parse(data);

                    if (data.length === 1) {
                        if (data[0] === null || data[0] === undefined) {
                            console.warn("Unsual data found: ", data);
                            return;
                        }
                    }
                    
                    const newData = data.map((el) => {
                        if (el === null) return el;
                        const custom = el.custom;
                        el = Object.assign(el, custom);
                        delete el.custom;
                        return el;
                    });

                    const alertName = data[0].alert_name;
                    // console.log("New Data: ", data);

                    let alertData = data;

                    let msg = "";
                    if (alertData.length != 0) {
                        // msg = self.alertMsg(alertName, alertData);

                        // 拼接消息
                        msg = " " + data[0].alert_name.replace("_", " ").toUpperCase() + " - " + data[0].broker.replace("_", " ").toUpperCase() + "\n";
                        let count = 0;
                        for (const item in data[0]) {
                            if (state.alertNotificationShowItem[alertName][item]) {
                                let displayValue = data[0][item];

                                if (!isNaN(data[0][item]) && item != "login" && item != "order" && item != "orderTicket") {
                                    displayValue = displayValue.toFixed(2);
                                }

                                msg += " " + item.replace("_", "").toUpperCase() + ": " + displayValue;

                                if (count % 2 == 0) {
                                    msg += "\n";
                                }
                                count += 1;
                            }
                        }

                        // console.log(alertData[0].alert_name);

                        // if (alertData[0].alert_name == "big_lot") {
                        state.barrageIsShow = true;
                        const bdata = {
                            id: ++count,
                            avatar: "",
                            msg: msg,
                            time: 10,
                            type: MESSAGE_TYPE.NORMAL
                        };
                        // console.log(bdata);
                        state.barrageList.push(bdata);
                        state.barrageLoop = false;
                        // }

                        // console.log("弹幕: ", state.barrageList);
                        // console.log(msg);

                        // 获取当前alert的broker
                        const alertBroker = alertData[0].broker;

                        // 判断alert是否有object
                        let hasBook = true;
                        if (!alertData[0].hasOwnProperty("book")) hasBook = false;

                        // 读取localStorage配置
                        const dataSettings = localStorage.getItem("alertNotificationSettings");

                        // 检查该alert是否应该显示在信息流里面
                        if (dataSettings != null && dataSettings != undefined) {
                            // 找到对应的broker
                            const settings = JSON.parse(dataSettings).filter(i => i.broker == alertBroker).map(i => i.rule)[0];

                            // 找到对应的console设置
                            // const filterChannel = settings.filter(i => i.console == 1).map(i => i.value);

                            // 对当前收到的alert进行filter，看是否在console = 1的列表中，没有则剔除
                            // alertData = alertData.filter(i => filterChannel.includes(i.alert_name));
                        }

                        // 查看alert的组是否需要隐藏
                        const alertHideSettings = localStorage.getItem("alertHide");
                        if (alertHideSettings != null && alertHideSettings != undefined && hasBook) {
                            const settings = JSON.parse(alertHideSettings);
                            // 对alert的组进行filter隐藏
                            alertData = alertData.filter(i => !settings.includes(i.book));
                        }

                        // 展示alert
                        if (alertData.length != 0) {
                            // console.log("Final Data: ", alertData);
                            // 更新缓存逻辑
                            const keyForAll = "alertCache:all";

                            const key = "alertCache:" + alertData[0].broker + ":" + alertData[0].alert_name;

                            // 个别alert信息
                            if (localStorage.getItem(key) == null || localStorage.getItem(key) == undefined) {
                                const localData = alertData;
                                localStorage.setItem(key, JSON.stringify(localData));
                            } else {
                                const localData = JSON.parse(localStorage.getItem(key));
                                localData.unshift(alertData[0]);
                                localStorage.setItem(key, JSON.stringify(localData));
                            }

                            // 全部汇总alert信息
                            if (localStorage.getItem(keyForAll) == null || localStorage.getItem(keyForAll) == undefined) {
                                const localDataAll = alertData;
                                localStorage.setItem(keyForAll, JSON.stringify(localDataAll));
                                state.alertAll = [];
                                state.alertAll = alertData;
                            } else {
                                const localDataAll = JSON.parse(localStorage.getItem(keyForAll));
                                localDataAll.unshift(alertData[0]);
                                if (state.alertAll.length == 50) {
                                    state.alertAll.pop();
                                }
                                state.alertAll.unshift(alertData[0]);
                                localStorage.setItem(keyForAll, JSON.stringify(localDataAll));
                            }

                            // 更新alert计数器
                            state.alertBadage = _.mapValues(state.alertBadage, () => 0);
                            Object.keys(localStorage).forEach(function (key) {
                                if (key.indexOf("alertCache:") != -1 && key != "alertCache:all") {
                                    let localData = localStorage.getItem(key);
                                    if (localData == undefined || localData == null) {

                                    } else {
                                        localData = JSON.parse(localData);
                                        const splitName = key.split(":");
                                        const alertName = splitName[2];
                                        state.alertBadage.total += localData.length;
                                        state.alertBadage[alertName] += localData.length;
                                    }
                                }
                            });
                        }
                    }
                });
            }
        }
    },
    /**
     * Get broker list from permission local storage data
     * @param {*} state
     */
    initBroker (state) {
        const localData = localStorage.getItem("bp");
        if (localData == null || localData == undefined || localData == "") return;
        // get permission data from localstorage
        const data = JSON.parse(localData);

        // assign broker array
        state.listBroker = data.broker;

        // if more than one broker is available to this user, then permission is super user
        if (data.broker.length > 1) {
            // console.log("🚀 ~ file: Home.vue ~ line 592 ~ created ~ state.brokers",state.brokers);
            state.rootUser = 1;
        }

        // assign default value for selected broekr and id
        state.selectedBrokerId = data.broker[0].broker_id;
        state.selectedBroker = data.broker[0].broker;
    },
    /**
     * Remove alert cache by filter
     * alertPattern: [<broker>, <alertName>]
     * @param {*} state
     * @param {*} alertPattern
     */
    alertCacheRemove (state, alertPattern = []) {
        let patternKey = "alertCache:";

        if (alertPattern.length == 1) {
            patternKey += alertPattern[0] + ":";
        }

        if (alertPattern.length == 2) {
            patternKey += alertPattern[1];
        }

        Object.keys(localStorage).forEach(function (key) {
            if (key.indexOf(patternKey) != -1) {
                localStorage.removeItem(key);
            }
        });
    },
    /**
     * Get first n elememnt in alert localstorage
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    alertCacheAllInitial (state, n) {
        const keyForAll = "alertCache:all";
        if (localStorage.getItem(keyForAll) != null && localStorage.getItem(keyForAll) != undefined) {
            const data = JSON.parse(localStorage.getItem(keyForAll));
            state.alertAll = data.slice(0, n);
        } else {
            state.alertAll = [];
        }
    },
    /**
     * Flush alert data into localStorage
     * @param {*} state
     * @param {*} data
     */
    alertCacheSave (state, data) {
        const keyForAll = "alertCache:all";

        const key = "alertCache:" + data[0].broker + ":" + data[0].alert_name;

        if (localStorage.getItem(key) == null || localStorage.getItem(key) == undefined) {
            const localData = data;
            localStorage.setItem(key, JSON.stringify(localData));
        } else {
            const localData = JSON.parse(localStorage.getItem(key));
            localData.unshift(data[0]);
            localStorage.setItem(key, JSON.stringify(localData));
        }

        if (localStorage.getItem(keyForAll) == null || localStorage.getItem(keyForAll) == undefined) {
            const localDataAll = data;
            localStorage.setItem(keyForAll, JSON.stringify(localDataAll));
            state.alertAll = [];
            state.alertAll = data;
        } else {
            const localDataAll = JSON.parse(localStorage.getItem(keyForAll));
            localDataAll.unshift(data[0]);
            if (state.alertAll.length == 50) {
                state.alertAll.pop();
            }
            state.alertAll.unshift(data[0]);
            localStorage.setItem(keyForAll, JSON.stringify(localDataAll));
        }
    },
    alertCount (state) {
        state.alertBadage = _.mapValues(state.alertBadage, () => 0);
        Object.keys(localStorage).forEach(function (key) {
            if (key.indexOf("alertCache:") != -1 && key != "alertCache:all") {
                let localData = localStorage.getItem(key);
                if (localData == undefined || localData == null) {

                } else {
                    localData = JSON.parse(localData);
                    const splitName = key.split(":");
                    const alertName = splitName[2];
                    state.alertBadage.total += localData.length;
                    state.alertBadage[alertName] += localData.length;
                }
            }
        });
    },
    /**
     * 清除小铃铛
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    alertCountClear (state) {
        state.alertBadage = 0;
    },
    /**
     * Toggle snack message
     * @param {*} state
     * @param {*} config
     */
    toggleSnackMessage (state, config) {
        state.snackbar = config.visible;
        state.snackcolor = config.color;
        state.snackmsg = config.message;
    },
    /**
     * setupup socket io connection
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    socketBuild (state) {
        if (state.socket == null) {
            state.socket = io.connect(SOCKETIOURL, {
                transports: ["websocket"],
                upgrade: false
            });
            // console.log(state.socket);

            state.socket.on("connect", (message) => {
                // console.log("Connection data: " + message);
            });

            setTimeout(function () {
                state.connectId = state.socket.id;
                // localStorage.setItem('connect_id', state.connect_id);
            }, 1000);
        } else {
            state.socket.connect();
        }
    },
    socketBuildWs (state) {
        if (state.socket == null) {
            // console.log("socket url: ", SOCKETIOURL);
            state.socket = new WebSocket(socket);
            // console.log(state.socket);

            // state.socket.onopen = function(message) {
            //     console.log("Connection data: " + message);
            // };

            setTimeout(function () {
                state.connectId = state.socket.id;
                // localStorage.setItem('connect_id', state.connect_id);
            }, 1000);
        } else {
            state.socket.close();
        }
    },
    /**
     * destory socket
     * @param {object} state
     */
    socketDestroy (state) {
        // console.log("destory socket");
        if (state.socket != null) {
            state.socket.disconnect();
        }
    },
    dismissSnackbar (state) {
        // console.log("Access dismiss");
        state.snackbar = false;
    }
    // permissionCheck(state) {
    //     let encryptData = localStorage.getItem("ul");
    //     if (encryptData != undefined && encryptData != null) {
    //         let existingPermission = CryptoJS.AES.decrypt(encryptData, ENCRYPT_KEY);
    //         let userLevel = parseInt(existingPermission.toString(CryptoJS.enc.Utf8).split(",")[0]);
    //         state.userLevel = parseInt(userLevel);
    //     }
    //     // console.log("Passing Page Permission: ", parseInt(pagePermission));
    //     // console.log("Calcualted User Permission: ", parseInt(userLevel));
    //     // if (parseInt(pagePermission) >= parseInt(userLevel)) return true;

    //     return false;
    // },
    // decryptBrokerId(state) {
    //     let encryptData = localStorage.getItem("bi");
    //     if (encryptData != undefined && encryptData != null) {
    //         let existingPermission = CryptoJS.AES.decrypt(encryptData, ENCRYPT_KEY);
    //         let brokerId = parseInt(existingPermission.toString(CryptoJS.enc.Utf8).split(",")[0]);
    //         state.brokerId = parseInt(brokerId);
    //     }
    // }
};
export default mutations;
