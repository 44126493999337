import common from "@assets/js/common";

const state = {
    alertData: [],
    alertHeader: [
        { text: "Time", value: "time", align: "left", sortable: true },
        { text: "Login", value: "login", align: "left", sortable: true },
        { text: "Book", value: "book", align: "left", sortable: true },
        { text: "Symbol", value: "symbol", align: "left", sortable: true },
        { text: "Ticket", value: "order", align: "left", sortable: true },
        { text: "Total Volume", value: "totalVolume", align: "left", sortable: true },
    ],
    itemsPerPage: 10,
    sortBy: "time",
    sortDesc: true,
    loading: false,
    headerMap: {
        login: "Login", book: "Book", symbol: "Symbol", volume: "Volume", event: "Event",
        order: "Order", total_volume: "Total Volume", time: "Time"
    },
};

const actions = {
    /**
     * Load alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    loadAlertData({ commit, state, dispatch }, params) {

        commit("UPDATE_LOADING", true);
        let loadCall = dispatch("Alert/loadAlertData", params, { root: true });

        loadCall.then((res) => {
            console.log("SD: ", res);
            if (res.data.data_alert[params.alert] !== undefined) {
                let alertData = common.globalAlertFilter(res.data.data_alert[params.alert], params["broker_id"]);
                commit("UPDATE_ALERT_DATA", alertData);
            }
            commit("UPDATE_LOADING", false);
        })
    },
    /**
     * Establish alert streaming
     * @param   {[type]}  state      [state description]
     * @param   {[type]}  rootState  [rootState description]
     * @param   {[type]}  commit     [commit description]
     * @param   {[type]}  params     [params description]
     * @return  {[type]}             [return description]
     */
    upAlertStream({ state, rootState, commit }, params) {
        let brokerName = params.broker;
        let alert = params.alert;

        let alertChannel = brokerName + "-AlertsChannel:" + alert;

        rootState.socket.removeListener(alertChannel);

        rootState.socket.on(alertChannel, (data) => {

            console.log(data);

            data = JSON.parse(data);

            if (data[0] === null) return;

            let alertDataList = state.alertData;

            const newData = data.map((el) => {
                const custom = el.custom;
                el = Object.assign(el, custom);
                delete el.custom;
                return el;
            });

            newData.forEach((element) => {
                alertDataList.unshift(element);
            });

            commit("UPDATE_ALERT_DATA", alertDataList);
        });
    },
    /**
     * Remove alert stream listener
     * @param   {[type]}  rootState  [rootState description]
     * @param   {[type]}  params     [params description]
     * @return  {[type]}             [return description]
     */
    downAlertStream({ rootState }, params) {
        let brokerName = params.broker;
        let alert = params.alert;

        let alertChannel = brokerName + "-AlertsChannel:" + alert;

        rootState.socket.removeListener(alertChannel);
    }
};


const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update alert header
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    /**
     * Update loading status
     * @param   {[type]}  state    [state description]
     * @param   {[type]}  loading  [loading description]
     * @return  {[type]}           [return description]
     */
    UPDATE_LOADING(state, loading) {
        state.loading = loading
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}