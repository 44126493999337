<template>
    <div class="custom-menu">
        <v-menu offset-y v-for="(item, index) in items" :key="index" v-if="item.active == 1"
            open-on-hover :close-on-content-click="false" class="custom-menu">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="default" v-on:click="redirect(item)"
                    dark v-bind="attrs" v-on="on" large style="border-radius: 0;">
                    {{ item.text }}
                </v-btn>
                <!-- <v-btn color="primary" v-if="currentPath.includes(item.ref)" v-on:click="redirect(item)"
                    dark v-bind="attrs" v-on="on" large style="border-radius: 0;">
                    {{ item.text }}
                </v-btn> -->
            </template>
            <v-list v-if="item.children"  class="pl-0 pr-0 pt-0 pb-0" style="text-align: left;">
                <v-btn block v-on:click="redirect(submenu)" v-if="submenu.active == 1"
                    style="border-radius: 0px;"
                    v-for="(submenu, subindex) in item.children" :key="subindex">
                        {{ submenu.text }}
                    </v-btn>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "dataMenu",
    data () {
        return {
            currentRoute: [],
            items: [
                {
                    icon: "mdi-book-multiple",
                    text: "Book Monitor",
                    name: "book",
                    type: "link",
                    ref: "/",
                    active: 0
                }, {
                    icon: "mdi-book-multiple",
                    text: "LP",
                    name: "lp",
                    type: "link",
                    ref: "/lp",
                    active: 0
                }, {
                    icon: "mdi-chevron-down",
                    text: "Search",
                    name: "search",
                    type: "link",
                    ref: "/search",
                    active: 0
                },

                // {
                //     icon: "mdi-chevron-down", "icon-alt": "mdi-call-split", text: "Search", name: "search",
                //     type: "button", model: false, active: 0,
                //     children: [
                //         {
                //             icon: "mdi-circle-medium", text: "Raw History", name: "rawHistory",
                //             type: "link", ref: "/search/raw-history",active: 0,
                //         },{
                //             icon: "mdi-circle-medium", text: "Transaction", name: "transaction",
                //             type: "link", ref: "/search/transaction", active: 0,
                //         },{
                //             icon: "mdi-circle-medium", text: "Profit Loss", name: "profitLoss",
                //             type: "link", ref: "/search/profit-loss", active: 0,
                //         },{
                //             icon: "mdi-circle-medium", text: "Volume", name: "volume",
                //             type: "link", ref: "/search/volume", active: 0,
                //         },
                //     ],
                // },
                {
                    icon: "mdi-book-multiple",
                    text: "Alert",
                    name: "alert",
                    type: "link",
                    ref: "/alert/overview",
                    active: 0
                },

                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-bell",
                //     text: "Alert",
                //     name: "alert",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                //         {
                //             icon: "mdi-circle-medium",
                //             text: "Overview",
                //             name: "overview",
                //             type: "link",
                //             ref: "/alert/overview",
                //             active: 0,
                //         },
                //         {
                //             icon: "mdi-circle-medium",
                //             text: "History",
                //             name: "history",
                //             type: "link",
                //             ref: "/alert/history",
                //             active: 0,
                //         },
                //     ],
                // },

                // {
                //     icon: "mdi-bell",
                //     text: "Alert",
                //     name: "alert",
                //     type: "link",
                //     ref: "/alert",
                //     active: 0,
                // },

                // {
                //     icon: "mdi-book-multiple",
                //     text: "User Management",
                //     name: "users",
                //     type: "link",
                //     ref: "/user",
                //     active: 0,
                // },
                {
                    icon: "mdi-cog-outline",
                    text: "Settings",
                    name: "settings",
                    type: "link",
                    ref: "/settings",
                    active: 0
                },

                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-file-document-multiple",
                //     text: "Server",
                //     name: "server",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                //         {
                //             text: "Log",
                //             name: "log",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/server/log",
                //             active: 0,
                //         },
                //     ],
                // },

                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-chart-tree",
                //     text: "Validation",
                //     name: "validation",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                //         {
                //             text: "Net",
                //             name: "net",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/validation/net",
                //             active: 0,
                //         },
                //     ],
                // },
                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-dns-outline",
                //     text: "Risk",
                //     name: "risk",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                // {
                //     text: "Prometheus",
                //     name: "prometheus",
                //     icon: "mdi-circle-medium",
                //     type: "link",
                //     ref: "/risk/prometheus",
                //     active: 0,
                // },
                // {
                //     text: "Notification",
                //     name: "notification",
                //     icon: "mdi-circle-medium",
                //     type: "link",
                //     ref: "/risk/notification",
                //     active: 0,
                // },
                //     ],
                // },
                {
                    icon: "mdi-chevron-down",
                    "icon-alt": "mdi-tools",
                    text: "System",
                    name: "system",
                    type: "button",
                    model: false,
                    active: 0,
                    children: [
                        // {
                        //     text: "Prometheus",
                        //     name: "prometheus",
                        //     icon: "mdi-circle-medium",
                        //     type: "link",
                        //     ref: "/system/prometheus",
                        //     active: 0,
                        // },
                        {
                            text: "Snapshot",
                            name: "snapshot",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/snapshot",
                            active: 0
                        },
                        {
                            text: "Group",
                            name: "group",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/group",
                            active: 0
                        },
                        {
                            text: "Book Group Rules",
                            name: "bookGroupRules",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/book-group-rules",
                            active: 0
                        },
                        {
                            text: "Symbol Rule",
                            name: "symbolRule",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/symbol-rule",
                            active: 0
                        },
                        {
                            text: "Tick",
                            name: "tick",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/tick",
                            active: 0
                        },
                        {
                            text: "Tick Live",
                            name: "ticklive",
                            icon: "",
                            type: "link",
                            ref: "/system/ticklive",
                            active: 0
                        },
                        {
                            text: "Alert Configuration",
                            name: "alertConfiguration",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/alert-configuration",
                            active: 0
                        },
                        {
                            text: "Notification",
                            name: "notification",
                            icon: "mdi-circle-medium",
                            type: "link",
                            ref: "/system/notification",
                            active: 0
                        }
                        // {
                        //     text: "User Perf",
                        //     name: "userPerformance",
                        //     icon: "mdi-circle-medium",
                        //     type: "link",
                        //     ref: "/system/user-performance",
                        //     active: 0,
                        // },
                    ]
                }
                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-tools",
                //     text: "MT Admin",
                //     name: "mt-admin",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                //         {
                //             text: "Prometheus",
                //             name: "prometheus",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/system/prometheus",
                //             active: 0,
                //         }
                //     ],
                // },

                // rocky modify
                // {
                //     icon: "mdi-chevron-down",
                //     "icon-alt": "mdi-account-box",
                //     text: "Account",
                //     name: "account",
                //     type: "button",
                //     model: false,
                //     active: 0,
                //     children: [
                //         {
                //             text: "Settings",
                //             name: "profile",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/account/profile",
                //             active: 0,
                //         },
                //         {
                //             text: "Config",
                //             name: "config",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/account/config",
                //             active: 0,
                //         },
                //         {
                //             text: "Management",
                //             name: "management",
                //             icon: "mdi-circle-medium",
                //             type: "link",
                //             ref: "/account/management",
                //             active: 0,
                //         },
                //     ],
                // },

            ]
        };
    },
    computed: {
        currentPath () {
            console.log(localStorage.getItem("currentPath"));
            return localStorage.getItem("currentPath");
        }
    },
    watch: {
        $route: {
            handler () {
		        // this.currentRoute = this.$route.meta.bread;
                // this.bread = []
                // if(this.$route && this.$route.meta && this.$route.meta.breadID){
                // 	let arr = this.$route.meta.breadID.split('-')
                // 	this.getBread(arr,this.breadNavList)
                // } else {
                // 	console.error('字段`this.$route.meta.breadID`没有设置')
                // }
            }
        }
    },
    methods: {
        redirect (data) {
            if (data.children) return;
            console.log(data.ref);
            this.$router.push(data.ref).catch(() => {});
        }
    },
    mounted () {
        this.currentRoute = this.$route.meta.bread;

        const data = JSON.parse(localStorage.getItem("bp")).frontPermission;

        for (const item of this.items) {
            const name = item.name;
            if (data[name]) {
                item.active = 1;
                if (data[name].child.length > 0) {
                    const children = data[name].child;
                    if (item.children) {
                        for (const child of item.children) {
                            if (children.includes(child.name)) {
                                child.active = 1;
                            }
                        }
                    }
                }
            }
        }
    }
};
</script>
<style>
.v-list .v-list-item:hover {
    background: #0091DA;
    cursor: pointer;
}
</style>
