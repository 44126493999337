import common from "@assets/js/common";

const state = {
    alertData: [],
    alertHeader: [
        { text: "Time", value: "time", align: "left", sortable: true },
        { text: "Login", value: "login", align: "left", sortable: true },
        { text: "Book", value: "book", align: "left", sortable: true },
        { text: "Profit", value: "profit", align: "left", sortable: true },
        { text: "Vol Today", value: "volumeToday", align: "left", sortable: true },
    ],
    itemsPerPage: 10,
    sortBy: "time",
    sortDesc: true,
    loading: false,
    headerMap: {
        login: "Login", book: "Book", profit: "Profit", volume_today: "Volume Today",
        time: "Time"
    },
};

const actions = {
    /**
     * Load alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    loadAlertData({ commit, state, dispatch }, params) {

        commit("UPDATE_LOADING", true);
        let loadCall = dispatch("Alert/loadAlertData", params, { root: true });

        loadCall.then((res) => {
            let alertData = common.globalAlertFilter(res.data.data_alert[params.alert], params["broker_id"]);
            commit("UPDATE_ALERT_DATA", alertData);
            commit("UPDATE_LOADING", false);
        })
    },
};


const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update alert header
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    /**
     * Update loading status
     * @param   {[type]}  state    [state description]
     * @param   {[type]}  loading  [loading description]
     * @return  {[type]}           [return description]
     */
    UPDATE_LOADING(state, loading) {
        state.loading = loading
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}