<template>
    <v-app>
        <v-main>

            <router-view />

            <v-snackbar v-model="$store.state.snackbar" :color="$store.state.snackcolor"
                :timeout="$store.state.snacktimeout" top>
                {{ $store.state.snackmsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn :color="$store.state.snackclosecolor" text v-bind="attrs" :id="$store.state.snackbarbtnid"
                        @click="dismiss()"
                    >
                        {{ $store.state.snackbarbtn }}
                    </v-btn>
                </template>
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: "App",
    components: {},
    data: () => ({
        snackmsg: "Error",
        snackstatus: true,
        snackclosecolor: "white",
        alertChannel: []
    }),
    methods: {
        dismiss () {
            this.$store.commit("dismissSnackbar");
        }
    },
    mounted () {
        // this.loadAlertChannel();
        window.onbeforeunload = (e) => {
            // 刷新前重设版本号
            axios
                .get("version.json", {
                    headers: { "Cache-Control": "no-cache" }
                })
                .then((res) => {
                    const version = res.data.version;
                    console.log("Refresh at App.vue: ", version);
                    localStorage.setItem("version", version);
                });
        };
    },
    created () {}
};
</script>
<style>

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;

}
::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-corner {
    background-color: transparent;

}
::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9;
}

</style>
