import * as apiLoginDetail from "@components/api/pk/login-detail";
import * as apiManagerTradesOpen from "@components/api/pk/manager/trades-open";
import * as apiLoginHistory from "@components/api/pk/login-history";


const state = {
    dataDetail: [],
    dataTradesOpen: [],
    dataHistory: []
};

const actions = {
    /**
     * Api call to get login detail (brief info)
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    loadLoginDetail({ commit }, params) {
        apiLoginDetail.fetch(params).then((res) => {
            commit("UPDATE_DATA_DETAIL", res.data)            
        })
    }
};

const mutations = {
    /**
     * Update data detail key
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_DATA_DETAIL(state, data) {
        state.dataDetail = data;
    },
    /**
     * Update data trades open
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_DATA_TRADES_OPEN(state, data) {
        state.dataTradesOpen = data;
    },
    /**
     * Update data history
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_DATA_HISTORY(state, data) {
        state.dataHistory = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}