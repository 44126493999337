import * as apiAlertData from "@components/api/pk/alert/alert-data";

const state = {
    alertData: [],
    alertHeader: [],
    itemsPerPage: 10,
    sortBy: "time",
    sortDesc: true,
    loading: false,
    exceptHeader: ["Broker", "Alert Name", "Message", "Created At", "Updated At"]
};

const actions = {
    /**
     * Load alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    loadAlertData({}, params) {
        return apiAlertData.fetch(params);
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update alert header
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    /**
     * Update loading status
     * @param   {[type]}  state    [state description]
     * @param   {[type]}  loading  [loading description]
     * @return  {[type]}           [return description]
     */
    UPDATE_LOADING(state, loading) {
        state.loading = loading
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}